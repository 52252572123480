/* styles.css */
.min-h-screen {
  min-height: 100vh;
}

.bg-gradient {
  background: linear-gradient(180deg, #EBF5FF 0%, #FFFFFF 100%);
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.content-container {
  text-align: center;
  max-width: 48rem;
  margin: 0 auto;
}

.logo {
  margin-bottom: 2rem;
}

.logo h1 {
  font-size: 2.25rem;
  font-weight: 700;
  color: #2563EB;
}

@media (min-width: 768px) {
  .logo h1 {
    font-size: 3.75rem;
  }
}

.main-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.timer-icon {
  font-size: 2.25rem;
  color: #3B82F6;
  margin-bottom: 1.5rem;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.main-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .main-title {
    font-size: 1.875rem;
  }
}

.description {
  color: #4B5563;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.content-sections {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.update-notice {
  background-color: #EFF6FF;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.update-notice h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1E40AF;
  margin-bottom: 0.75rem;
}

.update-notice p {
  color: #1D4ED8;
  margin-bottom: 1rem;
}

.update-notice ul {
  color: #1D4ED8;
  text-align: left;
  list-style-type: disc;
  padding-left: 1.5rem;
}

.update-notice li {
  margin-bottom: 0.5rem;
}

.opensource-section {
  background-color: #F9FAFB;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.opensource-section h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 0.75rem;
}

.opensource-section p {
  color: #4B5563;
}

.archive-notice {
  background-color: #FFFBEB;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.archive-notice p {
  color: #B45309;
  font-size: 0.875rem;
  text-align: left;
}

.footer {
  margin-top: 2rem;
  color: #6B7280;
}

.footer p:first-child {
  margin-bottom: 0.5rem;
}

.footer p:last-child {
  font-size: 0.875rem;
}